import dayjs from 'dayjs';

export const formatDate = (date: string) => {
  return dayjs(date).format('DD.MM.YYYY');
};

export const formatDateISO = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

/*
  Generuje date w formacie ISO z godziną północy.
  Przydatne w przypadku gdy backend potrzebuje dokładnej godziny natomaist użytkownik w inpucie wybiera dzień/miesiąc/rok
*/
export const formatDateISOStatic = (date: Date) => {
  return `${dayjs(date).format('YYYY-MM-DD')}T00:00:00.000Z`;
};

export const formatDateWithoutYear = (date: string) => {
  return dayjs(date).format('DD.MM');
};

export const formatDateWithHour = (date: string | Date) => {
  return dayjs(date).format('DD.MM.YYYY HH:mm');
};

export const formatDateWithSeconds = (date: string) => {
  return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
};

export const onlyHours = (date: string) => {
  return dayjs(date).format('HH:mm:ss');
};

export const onlyHoursWithoutSeconds = (date: string) => {
  return dayjs(date).format('HH:mm');
};
